var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"account-pages my-5 pt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"card overflow-hidden"},[_vm._m(1),_c('div',{staticClass:"card-body pt-0"},[_vm._m(2),_c('div',{staticClass:"p-2"},[_c('form',{staticClass:"form-horizontal"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"tag":"a","to":"/auth/recoverpw"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Forgot your password? ")])],1)])])])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Don't have an account ? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"tag":"a","to":"/auth/register-1"}},[_vm._v("Signup now")])],1),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{staticClass:"text-dark",attrs:{"href":"/"}},[_c('i',{staticClass:"fas fa-home h2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-soft-primary"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"text-primary p-4"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Welcome Back !")]),_c('p',[_vm._v("Sign in to continue to Skote.")])])]),_c('div',{staticClass:"col-5 align-self-end"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/profile-img.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"/"}},[_c('div',{staticClass:"avatar-md profile-user-wid mb-4"},[_c('span',{staticClass:"avatar-title rounded-circle bg-light"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":require("@/assets/images/logo.svg"),"alt":"","height":"34"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"username","placeholder":"Enter username"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v("Remember me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("Log In")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" © 2020 Skote. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesbrand ")])
}]

export { render, staticRenderFns }